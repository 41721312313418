.loader-container {
  position: fixed;
  left: 0;
  top: 0;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.loader-container img {
  width: 100px;
  animation: pulsate 0.5s ease-in-out infinite alternate;
}

@keyframes pulsate {
  from {
    width: 100px;
  }
  to {
    width: 130px;
  }
}
