.header {
  img {
    width: 38px;
    @media screen and (min-width: 992px) {
      width: 48px;
    }
  }
  .logo-title {
    width: 135px;
    height: 18px;
    object-fit: contain;
    margin-left: 1rem;
  }
}

.footer {
  font-size: 0.8rem;
  // position: sticky;
  // bottom: 0;
  // left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  padding: 1.2rem 1rem;
  img {
    width: 36px;
  }
  @media screen and (min-width: 1024px) {
    //   position: fixed;
    justify-content: end;
  }
  a {
    font-family: "Roboto", sans-serif;
    font-weight: 200;
    color: rgba($color: #fff, $alpha: 0.7);
    text-decoration: none;
  }
}
