.email-screen {
  input {
    width: 100%;
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0.09);
    border: none;
    border-radius: 30px;
    font-weight: 900;
    font-size: 1.1rem;
    padding: 0.5rem 2rem;
    color: #fff;
    ::placeholder {
      color: rgba($color: #fff, $alpha: 0.5);
    }
  }
  p {
    font-size: 0.85rem;
    @media screen and (min-width: 992px) {
      margin-bottom: 3rem !important;
    }
  }
  .step {
    margin-bottom: 1rem;
    &-number {
      width: 48px;
      height: 48px;
      border-radius: 48px;
      border: solid 2.5px white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2rem;
      font-weight: 900;
      margin-right: 10px;
      @media screen and (min-width: 1024px) {
        width: 56px;
        height: 56px;
        border-radius: 56px;
        font-size: 2.5rem;
      }
    }
    &-content {
      &-title {
        font-size: 1.1rem;
        font-weight: 900;
      }
      &-description {
        font-size: 0.8rem;
        font-weight: 800;
      }
    }
  }
  button {
    border: none;
    background-color: transparent;
    color: #fff;
    border: solid 1px white;
    display: flex;
    font-weight: 800;
    align-items: center;
    justify-content: center;
    width: 50%;
    margin: 20px auto;
    padding: 0.8rem;
    border-radius: 50px;
    @media screen and (min-width: 1024px) {
      width: 70%;
    }
    img {
      margin-left: 0.8rem;
    }
  }
  .invalid-email-container {
    background: #921010;
    border-radius: 10px;
    .title {
      font-weight: 700;
      font-size: 1.4rem;
      line-height: 22.2px;
    }
    img {
      width: 30px;
      margin-right: 10px;
    }
    .description {
      font-size: 0.85rem;
      font-weight: 500;
    }
  }
  .email-sent-container {
    background: #1ca010;
    border-radius: 10px;
    .title {
      font-weight: 700;
      font-size: 1.4rem;
      line-height: 22.2px;
    }
    img {
      width: 30px;
      margin-right: 10px;
    }
    .description {
      font-size: 0.85rem;
      font-weight: 500;
    }
  }
}
