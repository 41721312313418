.email-address {
  font-size: 1rem;
  @media screen and (min-width: 1024px) {
    font-size: 1.2rem;
  }
}

h1.page-title {
  @media screen and (min-width: 992px) {
    margin-bottom: 1rem;
  }
}

.overlay {
  position: fixed;
  z-index: 10;
  left: 0;
  right: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba($color: #000000, $alpha: 0.9);
}

.d-flex.flex-column.orders-error {
  background: #921010;
  padding: 15px;
  border-radius: 10px;
  .title {
    line-height: 1.4rem;
    font-weight: bold;
    font-size: 1.4rem;
  }
  .description {
    font-size: 0.85rem;
    font-weight: 500;
  }
}

.btn-white {
  background: #fff;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border-radius: 30px;
  padding: 10px;
  color: #000000;
  font-weight: bold;
}
