.order {
  min-width: 300px;
  min-height: 230px;

  perspective: 1000px;
  margin-bottom: 20px;
  position: relative;

  @media screen and (min-width: 992px) {
    min-height: 120px;
  }
  .title {
    font-weight: 700;
    font-size: 1.4rem;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    @media screen and (min-width: 1024px) {
      font-size: 1.8rem;
    }
  }
  .price {
    font-weight: 700;
    font-size: 1.4rem;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
  }
  .time-and-place {
    font-size: 0.9rem;
    font-weight: 700;
    padding: 0;
    margin-bottom: 20px;
    @media screen and (min-width: 1024px) {
      font-size: 1.3rem;
    }
  }
  button {
    background: #108292;
    border: none;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 30px;
    padding: 10px 20px;
    font-size: 1.1rem;
    font-weight: 700;
    line-height: 1.1rem;
    img {
      margin-left: 10px;
    }
  }
}

.flip-card-inner {
  padding: 0;
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}
.clicked {
  z-index: 11;
}

.flip-card.clicked .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0%;
  top: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.row.col-12.flip-card-front {
  background-color: rgba($color: #fff, $alpha: 0.09);
  padding: 10px !important;
  border-radius: 10px;
}

.row.col-12.flip-card-back {
  height: 110%;
  @media screen and (min-width: 992px) {
    height: 140%;
  }
  background: #108292;
  padding: 10px 3px !important;
  color: white;
  transform: rotateY(180deg);
  border-radius: 10px;
  button {
    background: #1ca010;
    width: 100%;
    &.disabled {
      background-color: #cfd0cf;
      color: #636363;
    }
  }
}
.row.col-12.flip-card-back.success {
  background: #1ca010;
  .time-and-place {
    @media screen and (min-width: 1024px) {
      font-size: 0.75rem;
      font-weight: 400;
      margin-bottom: 0;
    }
  }
}
.row.col-12.flip-card-back.error {
  background: #921010;
  .time-and-place {
    @media screen and (min-width: 1024px) {
      font-size: 0.75rem;
      font-weight: 400;
      margin-bottom: 0;
    }
  }
}

.row.col-12.flip-card-back.success,
.row.col-12.flip-card-back.error {
  button {
    background: #fff;
    color: #000;
  }
}

.order-loading {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba($color: #000, $alpha: 0.5);
  top: 0;
  left: 0;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
}
