.radio {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  border: solid 1px #fff;
  padding: 4px;
  &.error {
    border-color: red;
  }
  .circle {
    background-color: #fff;
    width: 100%;
    height: 100%;
    border-radius: 20px;
  }
}
