@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  font-family: "Barlow", sans-serif;
}
body {
  background-color: #000;
  color: #fff;
}

.fw-600 {
  font-weight: 600;
}

.page-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}
.flex-1 {
  flex: 1;
}

body iframe {
  z-index: 0 !important;
  display: none !important;
}

h1.page-title {
  font-size: 3rem;
  width: 100%;
  text-align: center;
  font-weight: bold;
  @media screen and (min-width: 992px) {
    font-size: 4rem;
    margin-top: 5rem;
    margin-bottom: 2rem;
  }
}

.small {
  font-size: 0.8rem;
}
.min-vh-75 {
  min-height: 73vh;
}

.row {
  margin: 0;
}

.container {
  max-width: 900px;
}
